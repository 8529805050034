<template>
  <el-row type="flex" justify="center" align="middle" :style="{ flex: 1 }">
    <el-col :xs="24" :sm="18">
      <el-card class="box-card password-card" v-loading="loading">
        <el-row
          type="flex"
          justify="center"
          :style="{ 'margin-bottom': '22px' }"
        >
          <el-col :xl="24" style="text-align: center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1400 1065.8"
              v-html="logo"
              class="password-logo"
            ></svg>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 1rem">
          <p class="password-welcome">
            Please enter and confirm your desired new password.
          </p>
        </el-row>
        <el-form
          @submit.native.prevent="submit"
          :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
          label-width="100px"
        >
          <el-row>
            <el-col :md="12">
              <el-form-item
                :error="
                  form.errors.first('password')
                    ? form.errors.first('password')
                    : null
                "
                label="Password"
                required
              >
                <el-input
                  v-model="password"
                  type="password"
                  @focus="form.errors.clear('password')"
                  v-input-focus
                  required
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="Confirm" required>
                <el-input
                  v-model="password_confirmation"
                  type="password"
                  required
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-button
            type="primary"
            plain
            medium
            native-type="submit"
            :style="{ width: '100%', 'margin-top': '1rem' }"
            :disabled="loading"
          >
            Submit
          </el-button>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<style lang="scss">
.password-logo {
  max-height: 150px;
  fill: rgba(212, 161, 0, 0.4);
  transition: fill 0.5s ease;
}

.password-card:hover .password-logo {
  fill: rgba(212, 161, 0, 0.9);
}

.password-welcome {
  font-size: 1.125rem;
  line-height: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
}
</style>

<script>
import Form from "form-backend-validation"
import svg from "@/theme/svg"

export default {
  name: "ResetPassword",

  mixins: [],

  data() {
    return {
      form: new Form(),
      logo: svg.comet,
      loading: false,
      password: "",
      password_confirmation: "",
    }
  },

  watch: {
    $route() {
      this.routeChange()
    },
  },

  computed: {
    action() {
      return "auth/reset"
    },
  },

  methods: {
    routeChange() {
      this.loading = true

      if (!this.$route.query.token || this.$route.query.email) {
        this.$router.replace("/403?link")
      }
    },
    submit() {
      let data = {
        email: this.$route.query.email,
        token: this.$route.query.token,
        password: this.password,
        password_confirmation: this.password_confirmation,
      }

      this.loading = true

      this.axios
        .post(this.action, data)
        .then(() => {
          this.loading = false
          this.$router.replace({
            name: "login",
            query: {
              reset: null,
            },
          })
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            if (
              resp.response.data.errors.password &&
              resp.response.data.errors.password.indexOf("Invalid token.") !==
                -1
            ) {
              this.$message({
                message:
                  "This reset token is invalid. Please request a new password reset email.",
                type: "error",
                duration: 0,
                showClose: true,
              })
            } else {
              this.form.onFail(resp)
            }
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },
}
</script>
